import React, { useState } from 'react';
import { Dialog, Tag } from '@blueprintjs/core';
import { orderBy } from 'lodash-es';

import { WorkableForm } from '@/app/organisms/WorkableForm/WorkableForm';
import { AvatarList } from '@/app/molecules/AvatarList/AvatarList';
import {
  WorkspaceIndex,
  WorkspaceShow,
  ContactIndex,
  USASpendingAwardIndex,
  OppIndex,
  OppShow
} from '@/types/__generated__/GovlyApi';

type WorkspaceFollowersProps = {
  formTitle?: string;
  workspace?: WorkspaceIndex | WorkspaceShow;
  type: WorkspaceIndex['workableType'];
  resource?: ContactIndex | USASpendingAwardIndex | OppIndex | OppShow;
};

export const WorkspaceFollowers = ({ formTitle, workspace, type, resource }: WorkspaceFollowersProps) => {
  const [isAddingFollowers, setIsAddingFollowers] = useState(false);

  const follows = workspace?.follows ?? [];
  const followers = orderBy(follows, ['stateInteger', 'organizationUser.organizationName', 'organizationUser.name'])
    .map(({ active, organizationUser, state }) => ({
      ...organizationUser,
      active,
      initials: organizationUser?.initials,
      state
    }))
    .filter(({ state }) => !['unfollowed', 'disinterested'].includes(state));

  const dialog = (
    <Dialog onClose={() => setIsAddingFollowers(false)} isOpen={isAddingFollowers} className="min-w-[50%] p-0">
      <WorkableForm
        title={formTitle}
        workableType={type}
        onCancel={() => setIsAddingFollowers(false)}
        onError={() => setIsAddingFollowers(true)}
        follows={follows ?? []}
        initialValues={{
          id: workspace ? workspace.id : undefined,
          workableType: workspace ? workspace.workableType : type,
          workableId: workspace ? workspace.workableId : resource?.id,
          organizationDefault: workspace ? workspace.organizationDefault : true
        }}
      />
    </Dialog>
  );

  if (followers.length) {
    return (
      <>
        <AvatarList avatarData={followers} onEdit={() => setIsAddingFollowers(true)} />
        {dialog}
      </>
    );
  } else {
    return (
      <div>
        <Tag minimal round interactive icon="plus" onClick={() => setIsAddingFollowers(true)}>
          Add followers
        </Tag>
        {dialog}
      </div>
    );
  }
};
